import { Box, Grid, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import AreeServiziCard from "../components/aree-e-servizi";
import Hero from "../utils/hero";
import Page from "../utils/page";
import Section from "../utils/section";
import theme from "../utils/theme";

// markup
const ChiSiamo = () => {
  return (
    <Page
      title="Farmacia a Settimo di Pescantina"
      description="Determinati e motivati nel cercare e assecondare la cura e il benessere dei pazienti, per offrire le soluzioni migliori personalizzando il consiglio."
    >
      <Hero
        color="#fff"
        title="Chi siamo"
        description="Determinati e motivati nel cercare e assecondare la cura e il benessere dei pazienti, per offrire le soluzioni migliori personalizzando il consiglio."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../images/farmacia-settimo-miglio-cosmesi.jpg"
            alt="Farmacia Settimo Miglio cosmesi"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/annamaria-caobelli.jpg"
                alt="Dott.ssa Annamaria Caobelli"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Dott.ssa Annamaria Caobelli
            </Typography>
            <Typography>Farmacista Titolare</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/giacomo-pomari.jpg"
                alt="Dott. Giacomo Pomari"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Dott. Giacomo Pomari
            </Typography>
            <Typography>Farmacista</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/elisabetta-antolini.jpg"
                alt="Dott.ssa Elisabetta Antolini"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Dott.ssa Elisabetta Antolini
            </Typography>
            <Typography>Farmacista consulente</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/giulia-formigoni.jpg"
                alt="Dott.ssa Giulia Formigoni"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Dott.ssa Giulia Formigoni
            </Typography>
            <Typography>Farmacista collaboratore</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../images/team/emanuela-adami.jpg"
                alt="Emanuela Adami"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Emanuela Adami
            </Typography>
            <Typography>Responsabile Dermocosmesi</Typography>
          </Grid>
        </Grid>
      </Section>

      <AreeServiziCard />
    </Page>
  );
};

export default ChiSiamo;
